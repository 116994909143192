<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="primary"
      icon="mdi-shield-crown-outline"
      title="Listado de usuarios del sistema"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="usuarios"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="1000px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            outlined
                            :readonly="showOption"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombre"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.apellido"
                            outlined
                            :readonly="showOption"
                            :rules="[v => !!v || 'El apellido es requerido']"
                            label="Apellido"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            outlined
                            :rules="emailRules"
                            :readonly="showOption"
                            label="E-mail"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.role"
                            outlined
                            :readonly="showOption"
                            :disabled="!showOption"
                            label="Rol"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-show="!showOption"
                            v-model="editedItem.password"
                            outlined
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            label="Nueva contraseña"
                            hint="Ingresa al menos 8 caracteres"
                            counter
                            @click:append="show1 = !show1"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-show="!showOption"
                            v-model="editedItem.confirmation"
                            outlined
                            :rules="rules"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            label="Confirmar contraseña"
                            hint="Ingresa al menos 8 caracteres"
                            counter
                            @click:append="show2 = !show2"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-show="!showOption"
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.valor="{ item }">
          ${{ item.valor }}MXN
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="blue darken-3"
            @click="showItem(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

  <script>
  import UsuariosApi from '@/services/api/Usuarios'

  export default {
    name: 'Usuarios',

    components: {
    },

    data () {
      return {
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        usuarios: [],
        editedIndex: -1,
        lazy: false,
        show1: false,
        show2: false,
        showOption: false,
        opciones: [
          { value: 'Si', text: 'Si' },
          { value: 'No', text: 'No' },
        ],
        headers: [
          {
            text: 'Nombre',
            align: 'center',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Apellidos',
            align: 'center',
            sortable: true,
            value: 'apellido',
          },
          {
            text: 'E-mail',
            align: 'center',
            sortable: true,
            value: 'email',
          },
          {
            text: 'Rol',
            align: 'center',
            sortable: true,
            value: 'role[0]',
          },
          {
            text: 'Acciones',
            align: 'center',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          nombre: '',
          apellido: '',
          email: '',
          password: '',
          confirmation: '',
        },
        defaultItem: {
          id: '',
          nombre: '',
          apellido: '',
          email: '',
          password: '',
          confirmation: '',
        },
        emailRules: [
          v => !!v || 'El correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Debes ingresar un correo electrónico válido',
        ],
      }
    },

    computed: {
      formTitle () {
        if (this.editedIndex !== -1 && this.showOption === true) {
          return 'Datos del usuario'
        } else if (this.editedIndex !== -1 && this.showOption === false) {
          return 'Modificar usuario'
        } else {
          return 'Nuevo usuario'
        }
      },
      rules () {
        const rules = []

        if (!this.allowSpaces) {
          const rule =
            v => (v || '').indexOf(' ') < 0 ||
              'No se permiten espacios en la contraseña'

          rules.push(rule)
        }

        if (this.editedItem.password) {
          const rule =
            v => (!!v && v) === this.editedItem.password ||
              'Las contraseñas no coinciden'

          rules.push(rule)
        }

        return rules
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchUsuarios () {
        UsuariosApi.getAllUsuarios()
          .then(usuarios => {
            this.usuarios = usuarios
          })
          .catch(error => console.log(error))
      },

      initialize () {
        this.fetchUsuarios()
      },

      showItem (item) {
        this.showOption = true
        this.editedIndex = this.usuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.usuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.showOption = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updUsuarios = this.editedItem
            const indexItem = this.editedIndex
            UsuariosApi.updateUsuario(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.usuarios[indexItem], updUsuarios)
                this.text = 'Registro modificado correctamente. Se recargará la página...'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
                setTimeout(() => { location.reload() }, 2000)
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            /* const addUsuarios = this.editedItem
            UsuariosApi.addUsuario(this.editedItem)
              .then(response => {
                addUsuarios.id = response.id
                this.usuarios.push(addUsuarios)
                this.text = 'Registro creado correctamente. Se recargará la página...'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
                setTimeout(() => { location.reload() }, 2000)
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              }) */
          }
          this.close()
        }
      },
    },
  }
  </script>
